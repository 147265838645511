var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"mb-2 orange lighten-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mt-2 mr-3",attrs:{"color":"success","to":_vm.clients?'/visits':''}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-account")]),_c('span',[_vm._v(_vm._s(_vm.clients))])],1)]}}])},[_c('i18n',{attrs:{"path":"tt.panclin"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mt-2 mr-3",attrs:{"color":"blue lighten-3","to":_vm.keys?'/keys/free':''}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-key")]),_c('span',[_vm._v(_vm._s(_vm.keys))])],1)]}}])},[_c('i18n',{attrs:{"path":"tt.pankeyava"}})],1),_vm._l((_vm.counters),function(v,n){return [_c('sc-counter',{key:n,staticClass:"mr-2",attrs:{"item":_vm.counters[n],"name":n}})]}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"to":_vm.workouts?'/todaywo':''}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t("icons.workouts")))]),_c('span',[_vm._v(_vm._s(_vm.workouts))])],1)]}}])},[_c('i18n',{attrs:{"path":"tt.panwoava"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }